/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Money from '../pages/money';
import CarBuyingBasicsRoutes from './car-buying-basics-routes';
import CarBuyingBasicsVideoRoutes from './car-buying-basics-video-routes copy';
import HomeBuyingBasicsRoutes from './home-buying-basics-routes';
import HookUpArticle from '../components/hookUpArticle';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import HookUpDigitalDesign from '../components/digital-design/hookUpDigitalDesign';
import MoneyTOC from '../components/tocs/money';
import Oops404Page from '../pages/oops-404';

export default function MoneyRoutes(props) {
    const root = props.match.path;
    const crumbs = `Managing Your Money|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <Money {...routerProps} crumbs={crumbs} toc={MoneyTOC} />}
        />

        {[
            HookUpArticle(root, crumbs, MoneyTOC, 'AchieveGoals.md', 'Money', 'Achieve Goals and Peace of Mind Through Financial Planning'),
            HookUpArticle(root, crumbs, MoneyTOC, 'CreditHistory.md', 'Money', 'Financial Fundamentals: Establishing and Improving Your Credit History'),
            HookUpArticle(root, crumbs, MoneyTOC, 'InOutCredit.md', 'Money', 'Understand the Ins and Outs of Credit Article'),
            HookUpArticle(root, crumbs, MoneyTOC, 'OperationCredit.md', 'Money', 'Operation: Monitor Your Credit'),
            HookUpArticle(root, crumbs, MoneyTOC, 'FinancialLiteracy.md', 'Money', 'Know the Importance of Financial Literacy Stability'),
            HookUpArticle(root, crumbs, MoneyTOC, 'DebtTraps.md', 'Money', 'How to Avoid — or Break — the Debt Trap Cycle'),
            HookUpArticle(root, crumbs, MoneyTOC, 'IDTheft.md', 'Money', 'Reduce Your Risk of Identity Theft'),
            HookUpArticle(root, crumbs, MoneyTOC, 'MajPurchase-Sign.md', 'Money', 'Make Major Purchases With Care and Confidence'),
            HookUpArticle(root, crumbs, MoneyTOC, 'ManagingCredit.md', 'Money', 'Financial Fundamentals: Managing and Monitoring Credit'),
            HookUpArticle(root, crumbs, MoneyTOC, 'OperationTaxPlanning.md', 'Money', 'Operation Tax Planning: Civilian Career'),
            HookUpArticle(root, crumbs, MoneyTOC, 'LendingApps.md', 'Money', 'What To Know About Lending Apps'),
            HookUpArticle(root, crumbs, MoneyTOC, 'TypesOfPay.md', 'Money', 'Why Understanding Different Types of Military Pay is Crucial for Your Finances'),
            HookUpArticle(root, crumbs, MoneyTOC, 'SpendingPersonalities.md', 'Money', 'Spending Personalities'),
            HookUpArticle(root, crumbs, MoneyTOC, 'BudgetingUncertainTimes.md', 'Money', 'Budgeting in Uncertain Times'),
            HookUpArticle(root, crumbs, MoneyTOC, 'RetirementTaxes.md', 'Money', 'Financial Planning for Transition: The Tax Implications of Retirement'),
            HookUpArticle(root, crumbs, MoneyTOC, 'WarningSigns.md', 'Money', 'Spot the Warning Signs of Identity Theft'),
            HookUpArticle(root, crumbs, MoneyTOC, 'FirstTimeFilers.md', 'Money', 'Tax Advice for First-Time Filers'),
            HookUpArticle(root, crumbs, MoneyTOC, 'FinancialWellBeing.md', 'Money', 'Financial Well-Being: How To Assess, Improve and Maintain Yours'),
            HookUpArticle(root, crumbs, MoneyTOC, 'Inflation.md', 'Money', 'The Impact of Inflation on Financial Decisions'),
            HookUpArticle(root, crumbs, MoneyTOC, 'TaxBenefits.md', 'Money', 'Don’t Wait: Plan Now to Reap Tax Benefits Later'),
            HookUpArticle(root, crumbs, MoneyTOC, 'TaxMistakes.md', 'Money', 'Avoid These Common Tax Mistakes'),
            HookUpArticle(root, crumbs, MoneyTOC, 'CuriousAboutCrypto.md', 'Money', 'Curious About Cryptocurrency?'),
            HookUpDigitalDesign(root, crumbs, MoneyTOC, 'NewYearChecklist', 'Use This Comprehensive Checklist to Prepare for a Prosperous New Year'),
        ]}

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Schedule-a-Checkup-For-Your-Finances', "It's Time to Schedule a Checkup — For Your Finances", crumbs,
                      '/assets/video/money/FINRED-AnimatedVideoUpdated-V-15Dec2020.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-TouchPointFinanceCheckup-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Kids-Answer-Financial-Questions', 'Kids Answer Financial Questions', crumbs,
                      '/assets/video/money/FINRED-KidsVideo-V-16Oct2020.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-KidsMoney-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Emergency-Funds-How-Much-Is-Enough', 'Emergency Funds: How Much Is Enough?', crumbs,
                      '/assets/video/money/FINRED-EmergencyFund-V.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-EmergencyFund-V.jpg',
                  ),
              ]}

              <CarBuyingBasicsVideoRoutes {...rps} crumbs={crumbs} />
            </Switch>
          )}
        />

        <Route
          path={`${root}/CarBuyingBasics`}
          render={(routerProps) => <CarBuyingBasicsRoutes {...routerProps} crumbs={crumbs} toc={MoneyTOC} />}
        />

        <Route
          path={`${root}/HomeBuyingBasics`}
          render={(routerProps) => <HomeBuyingBasicsRoutes {...routerProps} crumbs={crumbs} toc={MoneyTOC} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
