/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/page';
import ReactMarkdown from '../components/markdown';
import MarkLink from '../components/markLink';

export default function SiteMap() {
    return (
      <Page class="article" crumbs="Site Map">
        <ReactMarkdown
          source={`
## The Office of Financial Readiness Site Map

**[Home](/)**

**[Start Here](/StartHere/RoadToFinancialReadiness)**

**[Knowing Your Benefits and Entitlements](/Benefits)**

-   [Allowances and Benefits](/Benefits#Allowances-and-Benefits)
-   [Education Benefits](/Benefits#Education-Benefits)
-   [Flexible Spending Accounts](/Benefits#Flexible-Spending-Accounts)
-   [Housing](/Benefits#Housing)
-   [Additional Information](/Benefits#Additional-Information)

**[Managing Your Money](/Money)**

-   [Credit Fundamentals](/Money#Credit-Fundamentals)
-   [Digital Assets](/Money#Digital-Assets)
-   [Major Purchases](/Money#Major-Purchases)
-   [Pay and Savings](/Money#Pay-and-Savings)
-   [Tax Information](/Money#Tax-Information)
-   [Additional Information](/Money#Additional-Information)

**[Saving and Investing](/Saving)**

-   [Retirement Saving](/Saving#Retirement-Saving)
-   [Investing](/Saving#Investing)
-   [Additional Information](/Saving#Additional-Information)

**[Planning for the Future](/Planning)**

-   [Estate Planning](/Planning#Estate-Planning)
-   [Readiness](/Planning#Readiness)
-   [Insurance](/Planning#Insurance)
-   [Additional Information](/Planning#Additional-Information)

**[Understanding Consumer Protections](/Consumer-Protections)**

-   [Consumer Credit and Protections](/Consumer-Protections#Consumer-Credit-and-Protections)
-   [Additional Information](/Consumer-Protections#Additional-Information)

**Tools and Additional Resources**

-   [Sen$e Financial Education App](/ToolsAndAddRes/Sen$e)
-   [Shareable Resources](/ToolsAndAddRes/ShareableResources)
-   [Additional Resources](/ToolsAndAddRes/AdditionalRes)
-   [Calculators](/ToolsAndAddRes/Calculators)
-   [Financial Well-Being Assessment](/FWBA)
-   [How-To Videos](/ToolsAndAddRes/HowToVideos)
-   [Track Your Personal Net Worth](/ToolsAndAddRes/NetWorth)

**Trending Topics**

-   [Money in Real Life Blog](/Trending/Blog)
-   [Money in Real Life Resources](/Trending/MIRL)
-   [Policy, Law and Reports](/Trending/Policy)
-   [Take a Challenge](/Trending/SMChallenges)

** *[Assess Your Financial Well-Being](/FWBA)* **

** *[Find a Personal Financial Counselor](/pfcMap)* **

** *[Service Providers and Leaders](https://community.apan.org/wg/finred-lrl)* **

** *[MilSpouse Money Mission](https://www.milspousemoneymission.org/)* **

**[About Us](/About)**

-   [Financial Readiness FAQ](/About#Financial-Readiness-FAQ)
-   [Personal Financial Counseling FAQ](/About#Personal-Financial-Counseling-FAQ)
-   [Consumer Protection and Credit FAQ](/About#Consumer-Protection-and-Credit-FAQ)
-   [Additional Information and Assistance FAQ](/About#Additional-Information-and-Assistance-FAQ)
          `}

          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
