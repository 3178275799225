/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../../components/nonTOCPage';

import Link from '../../components/link';
import MarkLink from '../../components/markLink';
import ReactMarkdown from '../../components/markdown';
import NewsletterFormControl from '../../components/newsletterFormControl';

export default function MilitaryFinancialServiceProviderArchives (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},The Military Financial Service Provider eNewsletter Archives`}
        articleClass="subpage mos"
      >
        <ReactMarkdown
          source={`
## The Military Financial Service Provider eNewsletter Archives
This monthly eNewsletter supports service providers in delivering exceptional financial knowledge and education to service members and their families. Topics include policies and laws, resources and training opportunities.

### Stay Up to Date on Financial News
Get the latest news and updates directly to your inbox. Subscribe today:
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <NewsletterFormControl />

        <ReactMarkdown
          source={`
### New!
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3ca99c0">Read the latest edition of the Military Financial Service Provider eNewsletter<span className="fa fa-external-link" /></Link>

        <ReactMarkdown
          source={`
### View Past Editions of the Military Financial Service Provider eNewsletter:
**2024**
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3c51cd2">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3bcfbcd">November<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3b6a2ad">October<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3affe2c">September<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3a8f0b1">August<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3a32ba6">July<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/39d1aa9">June<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/396b63f">May<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3902001">April<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/38a148b">March<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3856fb4">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/379a85e">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2023</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/37c4fce">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/36ed596">September<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3699f0f">August<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/362385e">July<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/35e6860">June<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/354c578">May<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/35303ec">April<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/34ab8c9">March<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/345e1c9">February<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/33f9377">January<span className="fa fa-external-link" /></Link></li>
        </ul>
        <b>2022</b>
        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/33a3b9d">December<span className="fa fa-external-link" /></Link></li>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/335d6c5">November<span className="fa fa-external-link" /></Link></li>
        </ul>
      </Page>
    );
}
